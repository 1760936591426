import React from "react"
import Header from "./header"
import Footer from "./footer"
import Container from "./container"
import SEO from "./seo"

const Layout = props => {
  return (
    <Container>
      <SEO />
      <Header />
      {props.children}
      <Footer />
    </Container>
  )
}

export default Layout
