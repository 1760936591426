import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRssSquare } from "@fortawesome/free-solid-svg-icons"

import * as styles from "./footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <small className={styles.small}>
        Created by Dimitris Pramateftakis, &copy; {new Date().getFullYear()}
      </small>
    </footer>
  )
}

export default Footer
